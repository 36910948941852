import { createRoundButtonStyles } from 'components/common/Button/roundButtonStyles';
import React, { useMemo } from 'react';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef
} from 'react-polymorphic-types';

export type CircleButtonOwnProps = {
  intent?: false | 'primary' | 'secondary' | 'dark' | 'neutral';
  active?: boolean;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  circle?: 'SM' | 'LG' | 'XL' | false;
  outerClassName?: string;
  disableStyle?: boolean;
} & (
  | { href: string; target?: React.HTMLAttributeAnchorTarget }
  | { href?: undefined; target?: never }
);

export const CircleButtonDefaultElement = 'button';

const RoundButton: PolymorphicForwardRefExoticComponent<
  CircleButtonOwnProps & ParentClassNameProp,
  typeof CircleButtonDefaultElement
> = React.forwardRef(function Button<
  T extends React.ElementType = typeof CircleButtonDefaultElement
>(
  {
    as,
    active,
    className,
    outerClassName,
    intent = false,
    circle = false,
    disableStyle = false,
    ...restProps
  }: PolymorphicPropsWithoutRef<CircleButtonOwnProps, T>,
  ref: React.ForwardedRef<Element>
) {
  const Element: React.ElementType = as || CircleButtonDefaultElement;

  const classNames = createRoundButtonStyles()({
    intent,
    active,
    circle,
    disableStyle,
    className
  });

  const innerElement = useMemo(
    () => <Element ref={ref} {...restProps} className={classNames} />,
    [restProps, ref]
  );

  if (outerClassName) {
    return <div className={outerClassName}>{innerElement}</div>;
  }

  return innerElement;
});

RoundButton.displayName = 'RoundButton';

export { RoundButton };
