import { cva } from 'class-variance-authority';

export const createRoundButtonStyles = () => {
  return cva(['relative', 'flex', 'items-center', 'justify-center', 'ring-0', 'focus-default'], {
    variants: {
      intent: {
        false: [],
        primary: [],
        secondary: [],
        dark: [],
        neutral: []
      },
      circle: {
        SM: ['w-8', 'h-8'],
        LG: ['w-10', 'h-10'],
        XL: ['w-12', 'h-12'],
        false: []
      },
      active: {
        true: [],
        false: []
      },
      disableStyle: {
        true: [],
        false: ['rounded-full', 'shadow-sm', 'hover:shadow']
      }
    },
    defaultVariants: {
      disableStyle: false,
      active: false
    },
    compoundVariants: [
      {
        intent: false,
        disableStyle: false,
        className: [
          'bg-white',
          'text-primaryIcon',
          'hover:text-primaryIconHover',
          'text-sm',
          'hover:bg-neutralBackgroundHover'
        ]
      },
      {
        intent: 'primary',
        disableStyle: false,
        className: [
          'bg-tmBlue',
          'text-white',
          'hover:text-white',
          'hover:bg-tmBlueHover',
          'border',
          'border-primaryBorder'
        ]
      },
      {
        intent: 'secondary',
        disableStyle: false,
        className: [
          'bg-secondaryBackground',
          'border-secondaryBorder',
          'hover:bg-secondaryBackgroundHover',
          'text-primaryIcon',
          'hover:text-primaryIconHover'
        ]
      },
      {
        intent: 'dark',
        disableStyle: false,
        className: ['text-white', 'bg-primaryBackgroundDark', 'hover:bg-primaryBackgroundDarkHover']
      },
      {
        intent: 'neutral',
        disableStyle: false,
        className: [
          'bg-white',
          'hover:bg-primaryBackgroundHover',
          'border',
          'border-primaryBorder',
          'hover:border-primaryBorderHover'
        ]
      },
      {
        active: true,
        disableStyle: false,
        className: ['text-white', 'bg-primaryBackgroundDark', 'hover:bg-primaryBackgroundDarkHover']
      }
    ]
  });
};
